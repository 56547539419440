import React from 'react';
import { graphql } from 'gatsby';
import { Box } from 'rebass';
import { Link } from '../../../common';

export const query = graphql`
  fragment authoredLink on Drupal_ParagraphLink {
    fieldLink {
      title
      url {
        path
      }
    }
  }
`;

const AuthoredLink = ({
  entity: {
    fieldLink: {
      title,
      url: { path },
    },
  },
}) => (
  <Box flex="0 1 100%" maxWidth="100%" className="my-4">
    <Link href={path}>{title}</Link>
  </Box>
);

export default AuthoredLink;
