import React, { useContext } from 'react';

import { GlobalContext } from '../Layout';
import { LeftContainer } from '../../common';
import Overview from '../Overview/Overview';
import Office from './Office';

import TopicTags from '../../components/TopicTags/TopicTags';
import FAQLink from '../../components/FAQLink/FAQLink';

import { styleVars } from '../../lib/helpers/style';

const { mobileBreakpoint } = styleVars;

const SideBar = ({ overviewLinks = [], fieldTags, includeFAQLink = true }) => {
  const { recommendedLocations } = useContext(GlobalContext);

  overviewLinks.sort(function(a, b) {
    return a.weight - b.weight;
  });

  return (
    <>
      {/* mobile */}
      {overviewLinks?.length > 0 && (
        <LeftContainer className={`d-${mobileBreakpoint}-none pt-4`}>
          <Overview overviewLinks={overviewLinks} />
        </LeftContainer>
      )}

      <LeftContainer
        className={`sidebar order-2 pt-8 pt-${mobileBreakpoint}-2 mt-8 mt-${mobileBreakpoint}-0 order-${mobileBreakpoint}-0`}
      >
        {overviewLinks?.length > 0 && (
          <Overview
            overviewLinks={overviewLinks}
            className={`mb-6 d-none d-${mobileBreakpoint}-block flex-column align-items-start`}
          />
        )}

        {Object.keys(recommendedLocations.regional).length > 0 && (
          <Office recommendedLocations={recommendedLocations} className="mb-6" />
        )}

        {fieldTags?.length > 0 && <TopicTags tags={fieldTags} className="mb-6" />}

        {includeFAQLink && <FAQLink className="mb-6" />}
      </LeftContainer>
    </>
  );
};

export default SideBar;
