import React from 'react';
import { graphql } from 'gatsby';
import { Accordion } from '../../../common';

export const query = graphql`
  fragment authoredAccordion on Drupal_ParagraphAccordion {
    fieldAccordionItem {
      entity {
        ... on Drupal_ParagraphAccordionItem {
          fieldAccordionHeader
          fieldAccordionText {
            processed
          }
        }
      }
    }
  }
`;

const AuthoredAccordion = ({ entity: { fieldAccordionItem } }) => (
  <Accordion
    className="authored-accordion my-4"
    accordionItems={fieldAccordionItem.map(
      ({
        entity: {
          fieldAccordionHeader,
          fieldAccordionText: { processed },
        },
      }) => ({
        header: fieldAccordionHeader,
        content: processed,
      })
    )}
  />
);

export default AuthoredAccordion;
