import React from 'react';
import { graphql } from 'gatsby';

import { Video } from '../../../common';

export const query = graphql`
  fragment authoredVideo on Drupal_ParagraphVideo {
    fieldRemoteVideo {
      entity {
        ... on Drupal_MediaRemoteVideo {
          fieldMediaOembedVideo
        }
      }
    }
  }
`;

const AuthoredVideo = ({ entity: { fieldRemoteVideo } }) => {
  return <Video className="my-4" entity={fieldRemoteVideo} />;
};

export default AuthoredVideo;
