import React from 'react';
import { graphql } from 'gatsby';

import { Heading, MainContainer, PageContainer } from '../../common';
import SideBar from '../../components/SideBar/SideBar';
import { styleVars } from '../../lib/helpers/style';

import AuthoredAccordion from '../../components/Authored/Accordion/Accordion';
import AuthoredHeading from '../../components/Authored/Heading/Heading';
import AuthoredImage from '../../components/Authored/Image/Image';
import AuthoredLink from '../../components/Authored/Link/Link';
import AuthoredText from '../../components/Authored/Text/Text';
import AuthoredVideo from '../../components/Authored/Video/Video';
import AuthoredBlockquote from '../../components/Authored/Blockquote/Blockquote';
import AuthoredFactoid from '../../components/Authored/Factoid/Factoid';
import AuthoredDocument from '../../components/Authored/Document/Document';
import HorizontalRuler from '../../components/HorizontalRuler/HorizontalRuler';

export const query = graphql`
  fragment childPageContent on Drupal_NodeChildPage {
    fieldPageTitle
    fieldAuthoredSections {
      entity {
        entityId
        entityBundle
        ...authoredAccordion
        ...authoredHeading
        ...authoredImage
        ...authoredLink
        ...authoredText
        ...authoredVideo
        ...authoredBlockquote
        ...authoredFactoid
        ...authoredDocument
      }
    }
    fieldTags {
      entity {
        ...topicTags
      }
    }
  }
  query($nid: String!, $pagePath: String!) {
    drupal {
      nodeById(id: $nid) {
        ...childPageContent
      }
    }
    sitePage(path: { eq: $pagePath }) {
      children {
        ...childFiles
      }
    }
  }
`;

const ChildPage = ({ data, pageContext: { overviewLinks, pagePath } }) => {
  const node = data.drupal.nodeById;
  const files = data.sitePage.children;

  const { fieldAuthoredSections, fieldPageTitle, fieldTags } = node;

  const componentMapping = {
    // drupal machine name : component name
    accordion: AuthoredAccordion,
    heading: AuthoredHeading,
    image: AuthoredImage,
    link: AuthoredLink,
    text: AuthoredText,
    video: AuthoredVideo,
    blockquote: AuthoredBlockquote,
    factoid: AuthoredFactoid,
    document: AuthoredDocument,
    horizontal_rule: HorizontalRuler,
  };

  return (
    <>
      <MainContainer row className={`pt-${styleVars.mobileBreakpoint}-9 pb-9`}>
        <SideBar overviewLinks={overviewLinks} fieldTags={fieldTags} />

        <PageContainer className={`pt-8 pt-${styleVars.mobileBreakpoint}-0`}>
          <Heading as="h1" styledAs="h1" color="darkYellow">
            {fieldPageTitle}
          </Heading>
          {fieldAuthoredSections.map(({ entity }) => {
            const { entityBundle, entityId } = entity;
            const Component = componentMapping[entityBundle];
            return Component ? <Component key={entityId} entity={entity} files={files} /> : null;
          })}
        </PageContainer>
      </MainContainer>
    </>
  );
};

export default ChildPage;
