import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import { Box } from 'rebass';

import { Text } from '../../common';
import { Link } from '../../common';
import { shouldDisplayFieldOffice } from '../../lib/helpers/officeUtils';

import { getGoogleMapsUrl, transformHtmlWithLink } from '../../lib/helpers';

const textStyles = { fontSize: '1rem', color: 'grey6' },
  titleStyles = { marginBottom: '.7rem' };

const Office = ({ recommendedLocations, className = '' }) => {
  const sortedOffices = ['field', 'regional'];

  return (
    <Box className={className}>
      {sortedOffices.map((officeType) => {
        if (!Object.keys(recommendedLocations[officeType]).length) return null;

        if (officeType === 'field' && !shouldDisplayFieldOffice(recommendedLocations[officeType])) return null;

        const {
          office: { title, fieldPhoneNumber, fieldOfficeServices, fieldOfficeHours, fieldOfficeAddress },
        } = recommendedLocations[officeType];
        const { addressLine1, addressLine2, locality, postalCode } = fieldOfficeAddress;

        return (
          <Box key={officeType} className="mb-6">
            <Text fontWeight="bold" fontSize="3" as="p" color="grey5" sx={titleStyles}>
              {title}
            </Text>

            <Text {...textStyles}>{addressLine1},</Text>

            {addressLine2 && <Text {...textStyles}>{addressLine2},</Text>}

            <Text {...textStyles}>{`${locality}, TX ${postalCode}`}</Text>

            <Link className="mt-3 mb-3" href={getGoogleMapsUrl(fieldOfficeAddress)} style={{ display: 'block' }}>
              Get directions
            </Link>

            <Text {...textStyles}>Phone: {fieldPhoneNumber}</Text>

            <Text as="div" className="text--parsed--oneline" {...textStyles}>
              Hours: {ReactHTMLParser(fieldOfficeHours.processed, { transform: transformHtmlWithLink })}
            </Text>

            <Text fontWeight="bold" fontSize="3" as="p" color="grey5" sx={{ ...titleStyles, marginTop: '1.4rem' }}>
              This office can help with:
            </Text>

            {fieldOfficeServices.map(({ entity: { name } }) => (
              <Text {...textStyles} key={name}>
                {name}
              </Text>
            ))}
          </Box>
        );
      })}
    </Box>
  );
};

export default Office;
