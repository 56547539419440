import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const {
    drupal: { node, menuLink },
  } = useStaticQuery(graphql`
    query faqQuery {
      drupal {
        node: nodeQuery(
          filter: {
            conditions: [
              { field: "type", value: "faq", operator: EQUAL }
              { field: "status", value: "1", operator: EQUAL }
            ]
          }
        ) {
          entities {
            entityUrl {
              path
            }
            ... on Drupal_NodeFaq {
              fieldFaqIntro {
                entity {
                  ... on Drupal_ParagraphOptionalIntro {
                    fieldHeadingCopy
                  }
                }
              }
              fieldFaqMain
            }
          }
        }
        menuLink: menuLinkContentQuery(filter: { conditions: { field: "title", operator: EQUAL, value: "FAQs" } }) {
          entities {
            ... on Drupal_MenuLinkContentMenuLinkContent {
              link {
                url {
                  path
                }
              }
            }
          }
        }
      }
    }
  `);

  const nodeData = node.entities.find((entity) => entity.fieldFaqMain === true);
  const nodeH1 = nodeData?.fieldFaqIntro.entity.fieldHeadingCopy;
  const nodeUrl = nodeData?.entityUrl.path;
  const menuLinkUrl = menuLink?.entities[0]?.link.url.path;

  const faqName = nodeH1 || 'FAQs';
  const faqPath = nodeUrl || menuLinkUrl || '/faqs';

  return { faqName, faqPath };
};
