import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import useSearchPath from '../../hooks/useSearchPath';

import { Tag, Text } from '../../common';

const ListWrapper = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
`;

const ListItem = styled.li`
  margin: 0.5rem 0;
`;

export const query = graphql`
  fragment topicTags on Drupal_TaxonomyTermTags {
    entityId
    entityLabel
    entityUrl {
      path
    }
  }
`;

const TopicTags = ({ tags, className = '' }) => {
  const searchPath = useSearchPath();

  const renderTags = () =>
    tags.map(({ entity: { entityId, entityLabel } }) => {
      const urlParams = new URLSearchParams();

      urlParams.set('query', entityLabel);

      return (
        <ListItem key={entityId}>
          <Tag href={`${searchPath}/?${urlParams.toString()}`}>{entityLabel}</Tag>
        </ListItem>
      );
    });

  return (
    <nav className={`${className}`}>
      <Text fontWeight="bold" fontSize="3" as="p" color="grey5">
        Topic tags
      </Text>
      <ListWrapper>{renderTags()}</ListWrapper>
    </nav>
  );
};

export default TopicTags;
