import React from 'react';
import { graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import { Text as CommonText } from '../../../common';
import { usePDFReplace } from '../../../hooks/use-pdf-replace';

export const query = graphql`
  fragment authoredText on Drupal_ParagraphText {
    fieldText {
      processed
    }
  }
`;

const AuthoredText = ({
  entity: {
    fieldText: { processed },
  },
}) => {
  const sanitizedPDF = usePDFReplace(processed ? processed : null);
  return (
    <CommonText as="div" mY={3}>
      {ReactHtmlParser(sanitizedPDF)}
    </CommonText>
  );
};

export default AuthoredText;
