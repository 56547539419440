import React from 'react';
import { Box } from 'rebass';

import useFaqUrl from '../../hooks/useFaqUrl';

import { Link, Text } from '../../common';

const FAQLink = ({ className = '' }) => {
  const { faqPath } = useFaqUrl();

  return (
    <Box as="nav" className={`${className}`} flex="0 1 100%" maxWidth="100%">
      <Text marginBottom="3" fontSize="3" fontWeight="bold" as="p" color="grey6">
        Have a question?
      </Text>
      <Text fontSize="3" color="grey6" pb="3">
        Check out our FAQs page to find quick answers to your questions.
      </Text>
      <Link href={faqPath}>Find an answer</Link>
    </Box>
  );
};

export default FAQLink;
