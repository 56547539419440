import React from 'react';
import { Flex } from 'rebass';
import { Text } from '../../../common';
import { graphql } from 'gatsby';

import './Blockquote.scss';

/*Follows the same principle as the other authored items. useable in other templates this way
 *  <AuthoredBlockquote key={1} entity={blockQuoteEntity} />
 */

export const query = graphql`
  fragment authoredBlockquote on Drupal_ParagraphBlockquote {
    fieldQuote
    fieldAuthor
  }
`;

const Blockquote = ({ entity: { fieldQuote, fieldAuthor } }) => {
  return (
    <Flex sx={{ flexDirection: 'column' }} className="my-4">
      <Text className="quote">{fieldQuote}</Text>
      <Text pt={8}>&#8208; {fieldAuthor}</Text>
    </Flex>
  );
};

export default Blockquote;
