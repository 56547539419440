import React from 'react';
import { Flex } from 'rebass';
import { Text } from '../../../common';
import { graphql } from 'gatsby';

/*Follows the same principle as the other authored items. useable in other templates this way
 *  <AuthoredFactoid key={2} entity={factoidEntity} />
 */

export const query = graphql`
  fragment authoredFactoid on Drupal_ParagraphFactoid {
    fieldFactoidTitle
    fieldFactoidBody
  }
`;

const Factoid = ({ entity: { fieldFactoidTitle, fieldFactoidBody } }) => {
  return (
    <Flex p={['32px', '24px']} sx={{ bg: '#F0F2F6', flexDirection: 'column' }} className="my-4">
      <Text>{fieldFactoidTitle}:</Text>
      <Text pt={2}>{fieldFactoidBody}</Text>
    </Flex>
  );
};

export default Factoid;
