import React from 'react';
import { graphql } from 'gatsby';
import { Box } from 'rebass';
import { Link } from '../../../common';

export const query = graphql`
  fragment authoredDocument on Drupal_ParagraphDocument {
    fieldDocumentLinkText
    fieldDocument {
      entity {
        entityId
        ... on Drupal_MediaDocument {
          fieldMediaDocument {
            entity {
              drupalURL: url
              filename
            }
          }
        }
      }
    }
  }
`;

const AuthoredDocumentLink = ({
  entity: {
    fieldDocumentLinkText,
    fieldDocument: {
      entity: {
        fieldMediaDocument: {
          entity: { filename, drupalURL },
        },
      },
    },
  },
  files,
}) => {
  const { localURL } = files.find(({ url }) => url === drupalURL);
  return (
    <Box flex="0 1 100%" maxWidth="100%" className="my-4">
      <Link href={encodeURI(localURL)} downloadIcon>
        {fieldDocumentLinkText || filename}
      </Link>
    </Box>
  );
};

export default AuthoredDocumentLink;
