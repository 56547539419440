import React from 'react';
import { graphql } from 'gatsby';
import { Box } from 'rebass';
import Image from '../../../common/Image/Image';

export const query = graphql`
  fragment authoredImage on Drupal_ParagraphImage {
    fieldImage {
      entity {
        ...childImageField
      }
    }
  }
`;

const AuthoredImage = ({
  entity: {
    fieldImage: { entity },
  },
  files,
}) => (
  <Box className="my-4">
    <Image entity={entity} files={files} />
  </Box>
);

export default AuthoredImage;
