import React from 'react';
import CollapsingMenu from '../SideBar/CollapsingMenu';

const Overview = ({ overviewLinks, className = '' }) => (
  <CollapsingMenu
    className={className}
    heading="Overview"
    links={overviewLinks.map(({ entityLabel, url }) => {
      return { label: entityLabel, url };
    })}
  />
);

export default Overview;
