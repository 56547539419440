export function shouldDisplayFieldOffice(field) {
  const services = field.office.fieldOfficeServices;
  const serviceNames = services.map((service) => service.entity.name.trim().toUpperCase());

  return (
    serviceNames.includes('AUDIT AND INVESTIGATIONS') &&
    serviceNames.includes('ENFORCEMENT') &&
    serviceNames.includes('LICENSING')
  );
}
