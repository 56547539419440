import React from "react";
import { graphql } from "gatsby";
import { Heading as CommonHeading } from "../../../common";
import { Box } from 'rebass';

export const query = graphql`
  fragment authoredHeading on Drupal_ParagraphHeading {
    fieldHeadingCopy
    fieldHeadingType
  }
`;

const AuthoredHeading = ({
  entity: { fieldHeadingCopy, fieldHeadingType },
}) => {
    const isLarge = fieldHeadingType === "Large" ? true : false;
    return (
      <Box flex="0 1 100%" maxWidth="100%">
        <CommonHeading as={isLarge ? "h2" : "h3"} styledAs={isLarge ? "h2" : "h5"} color={isLarge ? "darkYellow" : "text"} sx={{ marginTop: `${isLarge ? 11 : 4}`}}>{fieldHeadingCopy}</CommonHeading>
      </Box>
    );
  };

export default AuthoredHeading;